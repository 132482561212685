import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown';
import { Button, CircularProgress, Snackbar, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import * as anchor from '@project-serum/anchor';

import { LAMPORTS_PER_SOL } from '@solana/web3.js';

import { useAnchorWallet } from '@solana/wallet-adapter-react';
import {
  WalletDialogButton,
  WalletMultiButton,
} from '@solana/wallet-adapter-material-ui';

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from './candy-machine';
import Header from './components/Header';
const ConnectButton = styled(WalletDialogButton)`
  background: #933b39 !important;
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div`
  margin-bottom: 3rem;
`; // add your styles here

const MintButton = styled(Button)`
  background: #933b39 !important;
  color: white !important;
  padding: 1rem 3rem !important;
  font-size: 125% !important;
`; // add your styles here

const StyledVideo = styled.video`
  max-width: 20rem;
  @media only screen and (min-width: 760px) {
    background: #fff;
    position: relative;
    display: block;
    width: 90%;
    height: auto;
    padding: 6%;
    box-shadow: inset 2px 0.2em 0.5em rgb(0 0 0 / 20%);
  }
`;
const StyledVideoWrapper = styled.div`
  margin: 2rem;
  position: relative;
  display: block;
  @media only screen and (min-width: 760px) {
    margin: 3.5rem 2rem 3.5rem 2rem;
    :after {
      content: '';
      position: absolute;
      display: block;
      width: 112%;
      height: 111%;
      top: -5%;
      left: -5%;
      background: #f8f8f8;
      z-index: -2;
      box-shadow: 0px 1em 2em -1em rgba(0, 0, 0, 0.4),
        0px 2em 2em -1em rgba(0, 0, 0, 0.3), 0px 3em 2em -1em rgba(0, 0, 0, 0.2),
        0px 4em 1.5em -1em rgba(0, 0, 0, 0.15),
        0px 2em 4em 0.5em rgba(0, 0, 0, 0.1), inset 0 0.2em 0.1em #fff;
    }
  }
`;
const StyledSubTitle = styled.h2`
  font-family: 'Caveat', sans-serif;
  text-align: center;
  color: #933b39;
  font-size: 500%;
  margin: 0;
  padding: 0;
`;

const StyledTitle = styled.h1`
  font-size: 350%;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const StyledStack = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledBalance = styled.div`
  text-align: right;
  margin-top: 0.5em;
`;

const StyledMain = styled.main`
  @media only screen and (min-width: 760px) {
    margin-top: -5rem;
  }
`;

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury,
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          'singleGossip',
          false,
        );
        console.log(status);

        if (!status?.err) {
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success',
          });
        } else {
          setAlertState({
            open: true,
            message: 'Mint failed! Please try again!',
            severity: 'error',
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (error.message.indexOf('0x138')) {
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error',
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(() => {
    (async () => {
      if (!wallet) return;

      const { candyMachine, goLiveDate, itemsRemaining } =
        await getCandyMachineState(
          wallet as anchor.Wallet,
          props.candyMachineId,
          props.connection,
        );

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  }, [wallet, props.candyMachineId, props.connection]);

  return (
    <>
      <Header>
        {wallet ? (
          <StyledStack>
            <WalletMultiButton />
            <StyledBalance>{(balance || 0).toLocaleString()} SOL</StyledBalance>
          </StyledStack>
        ) : (
          <ConnectButton>Connect Wallet</ConnectButton>
        )}
      </Header>
      <StyledMain>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '80vh' }}
        >
          <Grid item xs={12}>
            <StyledTitle>Collection Four</StyledTitle>
            <StyledSubTitle>Abstract</StyledSubTitle>
          </Grid>

          <Grid item xs={12}>
            <StyledVideoWrapper>
              <StyledVideo src="video.mp4" autoPlay muted loop>
                {' '}
              </StyledVideo>
            </StyledVideoWrapper>
          </Grid>
          <Grid item xs={12}>
            <MintContainer>
              {!wallet ? (
                <ConnectButton>Connect Wallet</ConnectButton>
              ) : (
                <MintButton
                  disabled={isSoldOut || isMinting || !isActive}
                  onClick={onMint}
                  variant="contained"
                >
                  {isSoldOut ? (
                    'SOLD OUT'
                  ) : isActive ? (
                    isMinting ? (
                      <CircularProgress />
                    ) : (
                      'MINT'
                    )
                  ) : (
                    <Countdown
                      date={startDate}
                      onMount={({ completed }) =>
                        completed && setIsActive(true)
                      }
                      onComplete={() => setIsActive(true)}
                      renderer={renderCounter}
                    />
                  )}
                </MintButton>
              )}
            </MintContainer>
          </Grid>
        </Grid>
        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
      </StyledMain>
    </>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error' | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;