import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import styled from 'styled-components';

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;
const StyledAppBar = styled(AppBar)`
  box-shadow: none !important;
`;
const StyledLogo = styled.img`
  width: 5rem;
  margin: 1.5rem 0;
`;
const Navbar = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledAppBar position="static" color="transparent">
      <StyledToolbar>
        <StyledLogo src="logo.png" alt="logo" />
        {children}
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Navbar;
